import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

export default function Video({
  src,
  autoPlay,
  playing,
  setPlaying,
  id,
  children,
}) {
  const videoRef = useRef(null)
  useEffect(() => {
    if (!playing) {
      videoRef.current.pause()
    }
  }, [playing])
  return (
    <div className="design__container">
      <video loop className="design" ref={videoRef} autoPlay={autoPlay}>
        <source src={src} type="video/mp4" />
      </video>
      {children &&
        React.Children.map(children, child => {
          return React.cloneElement(child, {
            setPlaying: setPlaying,
            playing: playing,
            videoRef: videoRef,
            id: id,
          })
        })}
    </div>
  )
}
Video.propTypes = {
  src: PropTypes.string.isRequired,
}
Video.defaultProps = {
  autoPlay: false,
}

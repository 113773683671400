/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"
import React from "react"
import "../styles/layout.scss"
import Navbar from "./Navbar"

const Layout = ({ children, title }) => {
  return (
    <React.Fragment>
      {<Navbar title={title} />}
      <main>{children}</main>
    </React.Fragment>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { useState, useEffect, Fragment } from "react"
import NavbarStyles from "../styles/Navbar.scss"
import "../styles/toggle.scss"
import Container from "./Container"
import Sun from "./Sun"
import Moon from "./Moon"
import { Link } from "gatsby"
import useDarkMode from "use-dark-mode"
export default function Navbar({ title }) {
  const darkMode = useDarkMode()
  // const activeStyle = {
  //   borderBottom: "2px solid white",
  // }
  // const [checked, setChecked] = useState(darkMode.value)
  // useEffect(() => {
  //   console.log(checked)
  //   darkMode.toggle()
  // }, [checked])
  return (
    <Fragment>
      {/*  <div className="notification">
        {" "}
        <Link>🚀 Open To Opportunities</Link>
      </div> */}
      <nav className="py-4 lg:py-6 bg-background-nav">
        <Container>
          <div className="flex justify-between text-white">
            <Link to="/" className="text-xl xl:text-4xl">
              ANURAG
            </Link>
            <div
              className="nav__right"
              onClick={darkMode.toggle}
              title={`Toggle to ${darkMode.value ? "Light" : "Dark"}`}
            >
              {/* <Link to="/about" className="nav__item" activeStyle={activeStyle}>
    About
    </Link> */}
              {/*  <Link to="/contact" className="nav__item" activeStyle={activeStyle}>
    Contact
    </Link> */}
              {/* <div onClick={darkMode.toggle} className="icon__container"> */}
              <Moon enable={darkMode.enable} />
              {/* darkMode.value === true ? (
    <Sun disable={darkMode.disable} />
    ) : (
    <Moon enable={darkMode.enable} />
    ) */}
              {/* </div> */}
              {/* <div className="wrapper">
    <input
    type="checkbox"
    id="switch"
    checked={darkMode.value}
    onClick={darkMode.toggle}
    defaultValue={true}
    />
    
    <label htmlFor="switch" className="switch-label">
    <div className="toggle"></div>
    </label>
    </div> */}
            </div>
          </div>
        </Container>
      </nav>
    </Fragment>
  )
}

import React from "react"

export const Play = ({ fill, id, playing, setPlaying, videoRef }) => {
  // console.log("TCL: Play -> id", id)
  return (
    <div
      className="play-pause-container"
      onClick={() => {
        setPlaying(id, videoRef)
        videoRef.current.play()
      }}
      style={{
        opacity: playing ? 0 : 1,
        pointerEvents: playing ? "none" : "auto",
      }}
    >
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M29 14.268c1.333.77 1.333 2.694 0 3.464L11 28.124c-1.333.77-3-.192-3-1.732V5.608c0-1.54 1.667-2.502 3-1.732l18 10.392z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
export const Pause = ({ fill, id, playing, setPlaying, videoRef }) => {
  return (
    <div
      className="play-pause-container"
      onClick={() => {
        setPlaying(null, videoRef)
        videoRef.current.pause()
      }}
      style={{
        opacity: playing ? 1 : 0,
        pointerEvents: playing ? "auto" : "none",
      }}
    >
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#fff"
          d="M9.333 5.333h5.373v21.49H9.333zM17.009 5.333h5.373v21.49h-5.373z"
        />
      </svg>
    </div>
  )
}

export default Play
/* 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="7" y="4" width="4.02941" height="16.1176" fill="#02AFAF"/>
<rect x="12.7568" y="4" width="4.02941" height="16.1176" fill="#02AFAF"/>
</svg>

 */
